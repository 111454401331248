.h1 {
  color: #0c1826;
  padding: 1rem 0;
  text-align: center;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 15px;
  font-size: 1.6rem;
  font-weight: 700;
}

@media (max-width: 769px) {
  h1 {
    font-size: 1.3rem !important;
    font-weight: 600 !important;
  }
}
