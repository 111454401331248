.container {
  padding: 30px;
  margin: 0 auto;
  max-width: 1050px;
  a {
    color: black;
  }
  .backArrowDesk {
    display: none;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 30px 50px;
    min-height: 400px;
    background-color: #fff;
    border-radius: 5px;
    .itemImage {
      max-width: 380px;
      padding-top: 30px;
      img {
        width: 100%;
      }
    }
    .itemDescription {
      display: flex;
      flex-direction: column;
      max-width: 400px;
      line-height: 2rem;
      padding-top: 30px;
      gap: 30px;
      padding-left: 30px;
    }
  }
}

@media (max-width: 769px) {
  .wrapper {
    flex-direction: column-reverse;
    align-items: center;
  }
  .backArrow {
    display: block;
  }
  .backArrowDesk {
    display: block;
  }
  img {
    margin-top: 30px;
  }
}
