.contacts {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-left: 20px;
  a {
    color: #000;
  }
  .line {
    display: flex;
    gap: 10px;
    margin-top: 12px;
    font-weight: 400;
    font-size: 0.95rem;
  }
  h3 {
    font-weight: 500;
    margin-bottom: 20px;
  }
}
