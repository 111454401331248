.menu {
  font-family: 'open sans';
  font-size: 15px;
  list-style: none;
  display: flex;

  li {
    padding: 10px 0;
    width: 95px;
    text-align: center;

    &:first-child {
      padding-left: 0;
    }
  }

  a {
    cursor: pointer;
    color: #0c1826;
  }

  @media (max-width: 1380px) {
    li {
      padding: 10px 15px;
    }
  }
}

.hideOnSmallScreens {
  @media (max-width: 1200px) {
    display: none;
  }
}

@media (max-width: 1200px) {
  .menu {
    flex-direction: column;
    li {
      text-align: left;
      padding-left: 20px;
      width: 120px;
    }
    li:first-child {
      padding-left: 20px;
      margin-top: 20px;
    }
  }
}
