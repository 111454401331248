.container {
  margin: 0 auto;
  max-width: 1200px;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin-top: 20px;
  }
}
