.wrapper {
  border-radius: 5px;
  background-color: #fff;
  padding: 20px;
  text-align: left;
  width: 270px;
  margin-right: 20px;
  transition: all 4s ease-in-out;
  .catList {
    position: sticky;
    top: 140px;
    h4 {
      font-weight: 700;
      padding: 10px 0 30px;
    }
    p {
      transition: all 0.15s ease-in-out;
      color: #000000;
      line-height: 2rem;
      font-size: 0.9rem;
      cursor: pointer;
    }
  }
}

.boldCategory {
  font-weight: 700;
  color: #213f5c;
}
