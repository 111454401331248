.wrapper {
  background-color: #121e2c;
  overflow: hidden;
  border-bottom: 10px solid #2f3945;
  .container {
    color: #fff;
    padding: 0 30px;
    min-height: 290px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    .logo {
      p {
        margin: 15px;
        font-weight: 300;
        font-size: 0.8rem;
      }
    }
    .contacts {
      .line {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 8px;
        font-weight: 300;
        font-size: 0.95rem;
      }
      h3 {
        font-weight: 500;
        margin-bottom: 20px;
      }
    }
    .navigation {
      li {
        list-style: none;
        line-height: 2.7rem;
      }
    }
  }
}

@media (max-width: 769px) {
  .container {
    flex-direction: column-reverse;
    gap: 32px;
    .contacts {
      display: none;
    }
    .navigation {
      padding: 20px 0;
      text-align: center;
    }
    .logo {
      p {
        text-align: center;
        padding-bottom: 30px;
      }
    }
  }
}
