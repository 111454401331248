.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  .loading {
    width: 80px;
    height: 80px;
  }
}
