@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'open sans';
  background-color: #f3f3f3;
}

.container {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  gap: 20px;
}

.active {
  font-weight: 700;
  color: #000 !important;
}
