.container {
  width: 85%;
  max-width: 1200px;
  margin: 0 auto;
  a {
    color: #000000;
  }
}

.itemContainer {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.7rem 0.5rem;
  height: 100%;
  background-color: #fff;
  cursor: pointer;
  span {
    color: #000;
    font-size: 1rem;
    font-weight: 500;
  }
  .image {
    display: flex;
    justify-content: center;
    height: 230px;
    img {
      width: 90%;
      height: 90%;
      margin-bottom: 10px;
      transition: all 0.25s ease-in-out;

      object-fit: contain;
    }
  }
  .itemPrice {
    display: flex;
    justify-content: center;
    padding: 0 5px;
  }
}

.itemContainer:hover {
  outline: 3px solid #2f3945;
  transition: all 0.2s ease-in-out;
  img {
    transform: scale(1.05);
  }
}

.description {
  margin-left: 7%;
  min-height: 85px;
  h2 {
    padding-right: 5px;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  p {
    font-size: 1rem;
    margin-top: 0.3rem;
    color: #484b4d;
  }
}

.none {
  display: none;
}

.hamburger {
  display: none;
}

.mobile {
  display: none;
}

@media (max-width: 1000px) {
  .itemContainer {
    .image {
      img {
        width: 80%;
        height: 80%;
      }
    }
  }
}

@media (max-width: 769px) {
  .itemContainer {
    min-height: 300px;
    padding: 10px 0;
    .image {
      max-height: 160px;
      margin-top: 0;
      img {
        width: 70%;
        height: 70%;
      }
    }
    .itemPrice {
      text-align: center;
      padding: 0 5px;
      .price {
        font-size: 0.9rem;
      }
      span {
        font-size: 0.9rem;
      }
    }
    .description {
      min-height: 70px;
      h2 {
        font-size: 0.9rem;
      }
    }
  }
  .itemContainer:hover {
    outline: none;
  }

  .desktop {
    display: none;
  }
}
