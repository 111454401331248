.searchBar {
  width: 420px;
}

@media (max-width: 1300px) {
  .searchBar {
    width: 390px;
  }
}

@media (max-width: 1200px) {
  .searchBar {
    width: 100%;
    padding: 0 20px;
  }
}

@media (max-width: 769px) {
  .searchBar {
    width: 100%;
  }
}
