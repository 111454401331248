.promoBanner {
  background-color: #2f3945;
  overflow: hidden;
  .banner {
    background: url('../../assets//img/1.webp');
    height: 250px;
    background-repeat: repeat;
    background-position: center;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
  }
}

.textLeft {
  color: #fff;
  font-size: 2.8rem;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 4.2rem;
  padding-top: 25px;
  padding-left: 50px;
  span {
    font-weight: bold;
    background-color: #cd1615;
    padding: 0 8px;
  }
  p {
    font-size: 1.7rem;
    font-weight: 400;
  }
}

.textRight {
  font-weight: 300;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 42px;
  line-height: 2.5rem;
  text-align: right;
  padding-right: 25px;
  color: #fff;
  font-size: 1.1rem;
  .tel {
    line-height: 1.7rem;
  }
  .line {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 10px;
  }
}

a {
  text-decoration: none;
  color: #fff;
  transition: all 0.15s ease-in-out;
}
.gap {
  gap: 20px;
  display: flex;
}

@media (max-width: 1100px) {
  .textRight {
    display: none;
  }
  .promoBanner {
    .banner {
      width: 100vw;
      height: 210px;
      display: flex;
      justify-content: center;
      overflow: hidden;
    }
  }
  .textLeft {
    font-size: 2rem;
    font-weight: 700;
    line-height: 3.8rem;
    padding: 12px 0;
    text-align: center;
    span {
      font-weight: bold;
      background-color: #cd1615;
      padding: 0 10px;
    }
    p {
      font-size: 1.3rem;
      font-weight: 300;
    }
  }
}
