.header {
  margin: 20px auto;
  width: 85vw;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  border-radius: 10px;
  position: sticky;
  top: 20px;
  background-color: #ffffff;
  color: #000000;
  transition: 0.5s;
  align-items: center;
  z-index: 100;
  gap: 10px;
}

.headerScrolled {
  margin: 0 auto;
  width: 100%;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #e9e8e8;
  top: 0;
  transition: 0.5s;
}

.hamburger {
  display: none;
}

@media (max-width: 1200px) {
  .hamburger {
    display: block;
  }
}

@media (max-width: 769px) {
  .header {
    flex-direction: column;
    padding-bottom: 1rem;
    padding: 0.5rem 0;
    img {
      margin: 0;
    }
  }
}
