.categoryBar {
  background-color: #bf2828;
  width: 100%;
  margin: 0 auto;
  color: #fff;
  font-weight: 400;
  font-size: 0.9rem;
  position: sticky;
  top: 107px;
  z-index: 50;
  overflow: hidden;
  height: 8px;
}

.categoryBarWrap {
  margin: 0 auto;
  width: 1160px;
}

@media (max-width: 769px) {
  .categoryBar {
    top: 173px;
  }
}
